<template>
  <div class="login">
    <img src="@/assets/images/logo1.svg" alt="" />
    <el-form :model="loginForm" :rules="rules" ref="loginForm" label-position="top">
      <h1>HipoPay销售系统</h1>
      <el-form-item label="账号" prop="username">
        <el-input v-model="loginForm.username" />
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input type="password" v-model="loginForm.password" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="init('loginForm')">登录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { login, merchantCountry } from '@/api/api'
import Cookie from '@/utils/cookie'
import Storage from '@/utils/storage'

export default {
  name: 'Login',
  data() {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          {
            required: true,
            message: '账号不能为空',
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: '密码不能为空',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    // 登录
    init(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          login(this.loginForm).then((res) => {
            if (res.code === 200) {
              const token = res.data.token_type + ' ' + res.data.access_token
              Cookie.setToken(token)
              this.merchantCountry()
              this.$router.push({ name: 'MerchantList' })
            }
          })
        } else {
          return false
        }
      })
    },
    // 所有国家
    merchantCountry() {
      merchantCountry().then((res) => {
        if (res.code === 200) {
          Storage.saveUser(res.data)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100%;
  padding: 40px 50px;
  overflow: auto;
  background-color: #eef3f6;
  & > img {
    width: 200px;
  }
  & > form {
    width: 460px;
    border-radius: 10px;
    background: #fff;
    padding: 40px 35px;
    margin: 50px auto 0;
    h1 {
      font-size: 26px;
      font-weight: bold;
      color: #000;
      margin-bottom: 40px;
      text-align: center;
    }
    button {
      width: 100%;
      margin-top: 20px;
    }
  }
}
</style>
